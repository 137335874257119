
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";
    import { IQuizInterface } from '../../ts/quiz/IQuizInterface';

    @Component({
        components: {
            Quiz
        },
    })

    // *****************************************************************************************************************
    export default class DevDesignPatterns extends Vue {
        // Data function
        public data() {
            return {
                behavioralQuiz:
                    require("@/assets/quizzes/design-patterns-behavioral-quiz.json"),

                creationalQuiz:
                    require("@/assets/quizzes/design-patterns-creational-quiz.json"),

                leadProgrammerIconPathAndName:
                    require("@/assets/images/icon_lead_programmer.png"),

                structuralQuiz:
                    require("@/assets/quizzes/design-patterns-structural-quiz.json"),
            };
        }
    }
